import HeaderShop from "../../../components/HeaderShop/Header";
import {
    Row,
    Modal,
    Input,
    message,
    Form,
    Card,
    Avatar,
    FloatButton,
    Button,
    Select,
} from "antd";
import "./Clients.css";
import { useEffect, useState } from "react";
import { host } from "../../../config";
import { Link, useNavigate } from "react-router-dom";

const { Meta } = Card;
const style = {
    cursor: "pointer",
    background: "#0092ff",
    color: "white",
};

export default function App() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [config, setConfig] = useState();
    const [data, setData] = useState();
    const [name, setName] = useState();
    const [color, setColor] = useState();
    const [url1, setUrl1] = useState("/print");
    const [selectedElement, setSelectedElement] = useState(null);
    const [TableData, setTableData] = useState();
    const [clients, setClients] = useState();
    const token = localStorage.getItem("token");
    const navigate = useNavigate();

    const options = [];

    useEffect(() => {
        fetch(host + "/api/config", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setConfig(res);
            })
            .catch((err) => message.error(err.name));

        fetch(host + "/api/category", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                res.sort((a, b) => a.name.localeCompare(b.name));

                setData(res);
                setTableData(res);
            })
            .catch((err) => message.error(err.name));

        fetch(host + "/api/clients", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setClients(res);
            })
            .catch((err) => message.error(err.name));
    }, []);

    const selectFilter = (owner, index) => {
        var filteredData = data.filter((e) => e.owner === owner);
        setSelectedElement(index);
        setTableData(filteredData);
    };

    const archive = (obj) => {
        fetch(host + "/api/archive", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                client: obj,
            }),
        })
            .then((res) => {
                res.json();
            })
            .then((res) => window.location.reload())
            .catch((err) => message.error(err.name));
    };

    const handleOk = (e) => {
        setIsModalOpen(false);
        fetch(host + "/api/category", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                name: name,
                color: color,
            }),
        })
            .then((res) => {
                res.json();
            })
            .then((res) => window.location.reload())
            .catch((err) => message.error(err.name));
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    var total = clients?.reduce((total, item) => total + item.summ, 0);
    console.log(clients);
    const handleScrollToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth",
        });
    };

    const sums = {};
    if (data) {
        for (const item of data) {
            const owner = item.owner;
            const weight = item.summ;

            if (sums[owner]) {
                sums[owner] += weight;
            } else {
                sums[owner] = weight;
            }
        }
    }

    // for (let i = 10; i < 36; i++) {
    //     options.push({
    //         value: i.toString(36) + i,
    //         label: i.toString(36) + i,
    //     });
    // }

    const uniqueNamesArray = Array.from(new Set(data?.map((obj) => obj.class)));

    uniqueNamesArray?.map((el) => {
        options.push({
            value: el || "NotSelected",
            label: el || "Not Selected",
        });
    });
    const handleChange = (value) => {
        setUrl1(`/print?${value}`);
    };
    return (
        <>
            <HeaderShop config={config} />
            <div className="center">
                <h1 style={{ padding: "20px" }}>Clients</h1>
                <p>Jami: {total}</p>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "0 auto",
                    }}
                >
                    <div
                        onClick={() => {
                            setIsModalOpen(true);
                        }}
                        style={{
                            width: "100px",
                            margin: "10px",
                            background: "#0092ff",
                            padding: "8px 0",
                            cursor: "pointer",
                            color: "white",
                            fontSize: "30px",
                        }}
                    >
                        +
                    </div>
                </div>
            </div>

            <div className="_margin">
                {data?.map((el) => {
                    return (
                        <Link to={"/shop/clients/category/" + el._id}>
                            <div
                                className="card-category"
                                style={{ background: el.color }}
                            >
                                {el.name}
                                <br />

                                {clients
                                    ?.filter((t) => t.class === el._id)
                                    .reduce(
                                        (total, item) => total + item.summ,
                                        0,
                                    )
                                    .toFixed(2)}
                            </div>
                        </Link>
                    );
                })}
                {/* <Table
                    columns={columns}
                    dataSource={TableData}
                    pagination={false}
                    loading={loading}
                /> */}
            </div>
            <Row gutter={[16, 16]} className="ant-grid">
                {/* {TableData?.map((element) => {
                    return (
                        <Col className="gutter-row" span={3}>
                            <div
                                style={style}
                                onClick={() =>
                                    navigate(`/shop/clients/${element._id}`)
                                }
                            >
                                <h1 className="center">{element.name}</h1>
                                <h3 className="center">
                                    {element.summ.toFixed(2)} gr
                                </h3>
                            </div>
                        </Col>
                    );
                })} */}
            </Row>
            <Modal
                title="Yangi Mijoz"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form>
                    <Form.Item label="Nomi" required>
                        <Input
                            placeholder="Nomi"
                            required={true}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item label="Color" required>
                        <Input
                            placeholder="Color"
                            required={true}
                            type="color"
                            onChange={(e) => setColor(e.target.value)}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <FloatButton.BackTop
                icon={"⬆️"}
                style={{
                    width: "50px",
                    height: "50px",
                    fontSize: "30px",
                    marginBottom: "70px",
                }}
            />

            <FloatButton
                style={{
                    width: "50px",
                    height: "50px",
                    fontSize: "30px",
                }}
                icon={"⬇️"}
                onClick={() => handleScrollToBottom()}
            />
        </>
    );
}
