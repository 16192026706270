import HeaderShop from "../../../components/HeaderShop/Header";
import {
    Row,
    Modal,
    Input,
    message,
    Form,
    Table,
    FloatButton,
    Button,
    theme,
    Calendar,
} from "antd";
import "./Divison.css";
import { useEffect, useState } from "react";
import { host } from "../../../config";
import { useNavigate } from "react-router-dom";

export default function App() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [config, setConfig] = useState();
    const [data, setData] = useState();
    const [name, setName] = useState();
    const [color, setColor] = useState();
    const navigate = useNavigate();

    const token = localStorage.getItem("token");

    const options = [];

    useEffect(() => {
        fetch(host + "/api/config", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setConfig(res);
            })
            .catch((err) => message.error(err.name));

        fetch(host + "/api/divison", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                res.sort((a, b) => a.name.localeCompare(b.name));

                setData(res);
            })
            .catch((err) => message.error(err.name));
    }, []);

    const selectFilter = (currentDay) => {
        fetch(host + "/api/divison/" + currentDay, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setData(res);
            })
            .catch((err) => {
                console.log(err);
                message.error(err);
            });
    };

    const columns = [
        {
            title: "T/R",
            dataIndex: "index",
            render(record, index) {
                return {
                    children: <div>{data.indexOf(index) + 1}</div>,
                };
            },
        },
        {
            title: "Model Nomi",
            dataIndex: "name",
            key: "name",
            render: (record, object, index) => {
                return (
                    <div
                        className="tablerow"
                        onClick={() => {
                            navigate(`/shop/divison/${object._id}`);
                        }}
                    >
                        {record}
                    </div>
                );
            },
        },
        {
            title: "Sanasi",
            dataIndex: "date",
            key: "date",
        },

        {
            title: "Natija",
            dataIndex: "",
            key: "date",
            render: (e) => {
                let keldi = 0;

                e.products.forEach((item) => {
                    if (item.weight) {
                        keldi += item.weight;
                    } else if (item.som) {
                        keldi += item.som / config?.gold_price;
                    } else if (item.usd) {
                        keldi +=
                            (item.usd * config?.dollar_price) /
                            config?.gold_price;
                    }
                });
                let sovda = parseFloat(
                    e.trade
                        .map((x) => x.plus)
                        .reduce((s, n) => s + n, 0)
                        .toFixed(2),
                );
                let mijoz = parseFloat(
                    e.client
                        .map((x) => x.plus)
                        .reduce((s, n) => s + n, 0)
                        .toFixed(2),
                );
                let asw = (sovda + mijoz - keldi).toFixed(2);
                return (
                    <span
                        style={{
                            padding: "10px 20px",
                            borderRadius: "8px",
                            color: "white",
                            background: asw < 0 ? "green" : "red",
                        }}
                    >
                        {asw}
                    </span>
                );
            },
        },

        {
            title: "Sovda",
            dataIndex: "",
            key: "",
            render: (e) => {
                let sovda = parseFloat(
                    e.trade
                        .map((x) => x.plus)
                        .reduce((s, n) => s + n, 0)
                        .toFixed(2),
                );

                return <span>{sovda}</span>;
            },
        },
        {
            title: "Status",
            dataIndex: "color",
            key: "color",
            render: (e) => {
                return (
                    <span
                        style={{
                            color:
                                e == "false"
                                    ? "red"
                                    : e == "true"
                                    ? "green"
                                    : "black",
                        }}
                    >
                        {e == "false" ? "New" : "Finished"}
                    </span>
                );
            },
        },
        // {
        //     title: "O'chirish",
        //     dataIndex: "",
        //     key: "x",
        //     render: (e) => (
        //         <Button type="primary" danger>
        //             Delete
        //         </Button>
        //     ),
        // },
    ];

    const handleOk = (e) => {
        setIsModalOpen(false);
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, "0");
        const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Oylar 0 dan boshlanadi
        const year = currentDate.getFullYear();
        const hours = String(currentDate.getHours()).padStart(2, "0");
        const minutes = String(currentDate.getMinutes()).padStart(2, "0");

        const formattedDateTime = `${day}.${month}.${year} ${hours}:${minutes}`;
        fetch(host + "/api/divison", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                name: name,
                color: false,
                date: formattedDateTime,
            }),
        })
            .then((res) => {
                res.json();
            })
            .then((res) => window.location.reload())
            .catch((err) => message.error(err.name));
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleScrollToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth",
        });
    };

    const onPanelChange = (value, mode) => {
        selectFilter(value.format("MM.YYYY"));
    };

    const sums = {};
    if (data) {
        for (const item of data) {
            const owner = item.owner;
            const weight = item.summ;

            if (sums[owner]) {
                sums[owner] += weight;
            } else {
                sums[owner] = weight;
            }
        }
    }

    // for (let i = 10; i < 36; i++) {
    //     options.push({
    //         value: i.toString(36) + i,
    //         label: i.toString(36) + i,
    //     });
    // }

    const uniqueNamesArray = Array.from(new Set(data?.map((obj) => obj.class)));

    uniqueNamesArray?.map((el) => {
        options.push({
            value: el || "NotSelected",
            label: el || "Not Selected",
        });
    });

    const __token = theme.useToken().token;

    const wrapperStyle = {
        width: 300,
        margin: "0 auto",
        border: `1px solid ${__token.colorBorderSecondary}`,
        borderRadius: __token.borderRadiusLG,
    };

    return (
        <>
            <HeaderShop config={config} />
            <div className="center">
                <h1 style={{ padding: "20px" }}>Divison</h1>
                <div style={wrapperStyle}>
                    <Calendar
                        fullscreen={false}
                        onChange={onPanelChange}
                        mode="year"
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "0 auto",
                    }}
                >
                    <div
                        onClick={() => {
                            setIsModalOpen(true);
                        }}
                        style={{
                            width: "100px",
                            margin: "10px",
                            background: "#0092ff",
                            padding: "8px 0",
                            cursor: "pointer",
                            color: "white",
                            fontSize: "30px",
                        }}
                    >
                        +
                    </div>
                </div>
            </div>

            <div className="_margin">
                {/* {data?.map((el) => {
                    return (
                        <Link to={"/shop/divison/" + el._id}>
                            <div
                                className="card-category"
                                style={{ background: el.color }}
                            >
                                {el.name}
                            </div>
                        </Link>
                    );
                })} */}
            </div>
            <Table
                style={{
                    width: "100%",
                }}
                columns={columns}
                dataSource={data}
                pagination={false}
                summary={(pageData) => {
                    const jami = pageData.reduce((sum, record) => {
                        let keldi = 0;

                        record.products.forEach((item) => {
                            if (item.weight) {
                                keldi += item.weight;
                            } else if (item.som) {
                                keldi += item.som / config?.gold_price;
                            } else if (item.usd) {
                                keldi +=
                                    (item.usd * config?.dollar_price) /
                                    config?.gold_price;
                            }
                        });
                        let sovda = parseFloat(
                            record.trade
                                .map((x) => x.plus)
                                .reduce((s, n) => s + n, 0),
                        );
                        let mijoz = parseFloat(
                            record.client
                                .map((x) => x.plus)
                                .reduce((s, n) => s + n, 0),
                        );
                        let asw = sovda + mijoz - keldi;

                        return sum + asw;
                    }, 0);

                    const total = pageData.reduce(
                        (sum, record) =>
                            sum +
                            parseFloat(
                                record.trade
                                    .map((x) => x.plus)
                                    .reduce((s, n) => s + n, 0),
                            ),

                        0,
                    );
                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                                <strong>Jami:</strong>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}></Table.Summary.Cell>
                            <Table.Summary.Cell index={2}></Table.Summary.Cell>
                            <Table.Summary.Cell index={3}>
                                <span
                                    style={{
                                        padding: "10px 20px",
                                        borderRadius: "8px",
                                        color: "white",
                                        background: jami < 0 ? "green" : "red",
                                    }}
                                >
                                    {jami.toFixed(2)}
                                </span>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={4}>
                                {total.toFixed(2)}
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
            />
            <Row gutter={[16, 16]} className="ant-grid">
                {/* {TableData?.map((element) => {
                    return (
                        <Col className="gutter-row" span={3}>
                            <div
                                style={style}
                                onClick={() =>
                                    navigate(`/shop/clients/${element._id}`)
                                }
                            >
                                <h1 className="center">{element.name}</h1>
                                <h3 className="center">
                                    {element.summ.toFixed(2)} gr
                                </h3>
                            </div>
                        </Col>
                    );
                })} */}
            </Row>
            <Modal
                title="Yangi Mijoz"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form>
                    <Form.Item label="Nomi" required>
                        <Input
                            placeholder="Nomi"
                            required={true}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Item>
                    {/* <Form.Item label="Color" required>
                        <Input
                            placeholder="Color"
                            required={true}
                            type="color"
                            onChange={(e) => setColor(e.target.value)}
                        />
                    </Form.Item> */}
                </Form>
            </Modal>
            <FloatButton.BackTop
                icon={"⬆️"}
                style={{
                    width: "50px",
                    height: "50px",
                    fontSize: "30px",
                    marginBottom: "70px",
                }}
            />

            <FloatButton
                style={{
                    width: "50px",
                    height: "50px",
                    fontSize: "30px",
                }}
                icon={"⬇️"}
                onClick={() => handleScrollToBottom()}
            />
        </>
    );
}
