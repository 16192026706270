import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { host } from "../../config";
import { Button } from "antd";
const App = (props) => {
    const navigate = useNavigate();
    const [gold, setGold] = useState();
    const [dollar, setDollar] = useState();
    const location = useLocation();
    const token = localStorage.getItem("token");

    useEffect(() => {
        fetch(host + "/api/config", {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                dollar: dollar,
                gold: gold,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.message === "Invalid token.") {
                    navigate("/");
                }
            })
            .catch((err) => console.log(err.message));
    }, [gold, dollar]);

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <ul className="nav-header">
                    <li
                        className={
                            location.pathname === "/shop" ? "selected-nav" : ""
                        }
                    >
                        <Link className="nav-Button" to="/shop">
                            <Button>Main</Button>
                        </Link>
                    </li>
                    <li
                        className={
                            location.pathname === "/shop/clients"
                                ? "selected-nav"
                                : ""
                        }
                    >
                        <Link className="nav-Button" to="/shop/clients">
                            <Button>Clients</Button>
                        </Link>
                    </li>
                    <li
                        className={
                            location.pathname === "/shop/archive"
                                ? "selected-nav"
                                : ""
                        }
                    >
                        <Link className="nav-Button" to="/shop/archive">
                            <Button>Archive</Button>
                        </Link>
                    </li>
                    <li
                        className={
                            location.pathname === "/shop/sellers"
                                ? "selected-nav"
                                : ""
                        }
                    >
                        <Link className="nav-Button" to="/shop/sellers">
                            <Button>Sellers</Button>
                        </Link>
                    </li>
                    <li
                        className={
                            location.pathname === "/shop/database"
                                ? "selected-nav"
                                : ""
                        }
                    >
                        <Link className="nav-Button" to="/shop/database">
                            <Button>Prices</Button>
                        </Link>
                    </li>
                    <li
                        className={
                            location.pathname === "/shop/base"
                                ? "selected-nav"
                                : ""
                        }
                    >
                        <Link className="nav-Button" to="/shop/base">
                            <Button>Base +</Button>
                        </Link>
                    </li>
                    <li
                        className={
                            location.pathname === "/shop/jizzax"
                                ? "selected-nav"
                                : ""
                        }
                    >
                        <Link className="nav-Button" to="/shop/jizzax">
                            <Button>Base -</Button>
                        </Link>
                    </li>
                    <li
                        className={
                            location.pathname === "/shop/cloud"
                                ? "selected-nav"
                                : ""
                        }
                    >
                        <Link className="nav-Button" to="/shop/cloud">
                            <Button>Cloud</Button>
                        </Link>
                    </li>
                    <li
                        className={
                            location.pathname === "/shop/history"
                                ? "selected-nav"
                                : ""
                        }
                    >
                        <Link className="nav-Button" to="/shop/history">
                            <Button>History</Button>
                        </Link>
                    </li>
                    <li
                        className={
                            location.pathname === "/shop/divison"
                                ? "selected-nav"
                                : ""
                        }
                    >
                        <Link className="nav-Button" to="/shop/divison">
                            <Button>Division</Button>
                        </Link>
                    </li>
                    {/* <li
                        className={
                            location.pathname === "/shop/draft"
                                ? "selected-nav"
                                : ""
                        }
                    >
                        <Link className="nav-Button" to="/shop/draft">
                            <Button>Draft</Button>
                        </Link>
                    </li>
                  <li
                        className={
                            location.pathname === "/shop/manage"
                                ? "selected-nav"
                                : ""
                        }
                    >
                        <Link className="nav-link" to="/shop/manage">
                            <Button>Managment</Button>
                        </Link>
                    </li>
                    <li
                        className={
                            location.pathname === "/shop/manage"
                                ? "selected-nav"
                                : ""
                        }
                    >
                        <Link className="nav-link" to="/shop/manage">
                            <Button>B@CK</Button>
                        </Link>
                    </li>{" "} */}
                </ul>
                <ul className="header-list">
                    <li className="list-item">
                        Gold price:
                        <input
                            type="text"
                            onChange={(e) => setGold(e.target.value)}
                            defaultValue={
                                props.config?.gold_price
                                    ? props.config?.gold_price
                                    : ""
                            }
                        />
                        sum
                    </li>
                    <li className="list-item">
                        Dollar price:
                        <input
                            type="text"
                            onChange={(e) => setDollar(e.target.value)}
                            defaultValue={
                                props.config?.dollar_price
                                    ? props.config?.dollar_price
                                    : ""
                            }
                        />
                        sum
                    </li>
                </ul>
            </div>
        </>
    );
};

export default App;
