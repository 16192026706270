import { message } from "antd";
import { useEffect, useState } from "react";
import { host } from "../../config";
import "./Print.css";
import { useLocation } from "react-router-dom";

export default function App() {
    const [data, setData] = useState();
    const token = localStorage.getItem("token");
    const location = useLocation();
    const data_ = location.state;
    useEffect(() => {
        fetch(host + `/api/base`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                res.sort(function (a, b) {
                    var nameA = a.name.toUpperCase();
                    var nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });

                if (data_) {
                    setData(data_);
                } else {
                    setData(res);
                }
            })
            .catch((err) => message(err));
    }, []);

    var owners = [];
    data?.forEach(function (item) {
        if (!owners.includes(item.owner)) {
            owners.push(item.owner);
        }
    });
    let tables = [];
    for (let i = 0; i < data?.length; i += 30) {
        tables.push(data?.slice(i, i + 30));
    }
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = String(currentDate.getFullYear());

    const formattedDate = `${day}.${month}.${year}`;

    const sums = {};

    if (data) {
        for (const item of data) {
            const owner = item.owner;
            const weight = item.weight;
            const plus = item.plus;

            if (sums[owner]) {
                sums[owner].weight += weight;
                sums[owner].plus += plus;
            } else {
                sums[owner] = { weight, plus };
            }
        }
    }
    return (
        <>
            Date: {formattedDate}
            <div className="table-container">
                {owners?.map((owner) => {
                    return (
                        <div className="table">
                            <h3>{owner}</h3>
                            Turish vesi:{" "}
                            <span style={{ fontSize: "24px" }}>
                                {sums[owner].weight.toFixed(2)}
                            </span>
                            Ustidigi puli bilan:
                            <span style={{ fontSize: "24px" }}>
                                {sums[owner].plus.toFixed(2)}
                            </span>
                            <table>
                                <tr>
                                    <th>Baza</th>
                                    <th>Nomi</th>
                                    <th>Vesi</th>
                                    <th>Sotildi</th>
                                    <th>Narxi</th>
                                    <th>=</th>
                                </tr>
                                {data?.map((el) => {
                                    if (el.owner === owner) {
                                        return (
                                            <tr>
                                                <td>{el.owner}</td>
                                                <td>{el.name}</td>
                                                <td>{el.weight.toFixed(2)}</td>
                                                <td width={"150px"}></td>
                                                <td width={"50px"}></td>
                                                <td width={"50px"}></td>
                                            </tr>
                                        );
                                    }
                                })}
                            </table>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
