import {
    Badge,
    Card,
    Button,
    Modal,
    Form,
    Input,
    InputNumber,
    message,
} from "antd";
import HeaderShop from "../../components/HeaderShop/Header";
import "./Shop.css";
import { useState, useEffect } from "react";
import { host } from "../../config";
import { Link, useNavigate } from "react-router-dom";

const App = () => {
    const [dollar, setDollar] = useState();
    const [gold, setGold] = useState();
    const [owner, setOwner] = useState();
    const [oldDollar, setOldDollar] = useState();
    const [name, setName] = useState();
    const [oldGold, setOldGold] = useState();
    const [oldRoom, setOldRoom] = useState();
    const [config, setConfig] = useState();
    const [room, setRoom] = useState();
    const [data, setData] = useState();
    const [goldPrice, setGoldPrice] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const token = localStorage.getItem("token");
    const navigate = useNavigate();

    const [isFModal, setIsFModal] = useState(true);

    const handleFOk = () => {
        setIsFModal(false);
        changeGoldPrice(goldPrice);
    };
    const handleFCancel = () => {
        setIsFModal(false);
    };

    const changeGoldPrice = (value) => {
        fetch(host + "/api/config", {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                gold: value,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.message === "Invalid token.") {
                    navigate("/");
                }
            })
            .catch((err) => console.log(err.message));
    };

    useEffect(() => {
        fetch(host + "/api/config", {
            method: "GET",
            headers: {
                Authorization: "Bareer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setConfig(res);
            })
            .catch((err) => console.log(err.message));
        fetch(host + "/api/room", {
            method: "GET",
            headers: {
                Authorization: "Bareer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setData(res);
            })
            .catch((err) => console.log(err.message));
    }, []);

    const UpdateGoldAndDollar = () => {
        fetch(host + "/api/room", {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: owner,
                dollar: dollar,
                gold: gold,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.message === "Invalid token.") {
                    navigate("/");
                }
            })
            .catch((err) => console.log(err.message));
    };

    const updateRoom = (id, room) => {
        room
            ? fetch(host + "/api/room/updateroom", {
                  method: "POST",
                  headers: {
                      Authorization: "Bearer " + token,
                      "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                      _id: id,
                      room: room,
                  }),
              })
                  .then((res) => res.json())
                  .then((res) => {
                      window.location.reload();
                  })
                  .catch((err) => console.log(err.message))
            : window.location.reload();
    };

    const handleOk = (e) => {
        setIsModalOpen(false);
        fetch(host + "/api/room", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                name: name,
                gold: oldGold,
                dollar: oldDollar,
                summ: oldRoom,
            }),
        })
            .then((res) => {
                res.json();
            })
            .then((res) => window.location.reload())
            .catch((err) => message.error(err.name));
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [isHovered, setIsHovered] = useState(false);
    const [hoveredElementId, setHoveredElementId] = useState(null); // Track the hovered element ID

    const handleMouseEnter = (id) => {
        setHoveredElementId(id); // Set the hovered element ID
    };

    const handleMouseLeave = () => {
        setHoveredElementId(null); // Clear the hovered element ID when mouse leaves
    };

    if (token === null) {
        return "404";
    } else if (!token) {
    } else {
        return (
            <>
                <HeaderShop config={config} />
                <div>
                    <Button
                        onClick={() => {
                            setIsModalOpen(true);
                        }}
                        style={{ margin: "30px" }}
                        type="primary"
                    >
                        Add
                    </Button>
                    <Modal
                        title="New Base"
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                    >
                        <Form>
                            <Form.Item label="Name" required>
                                <Input
                                    placeholder="Name"
                                    required={true}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Form.Item>
                            <Form.Item label="Dollar" required>
                                <InputNumber
                                    type="number"
                                    placeholder="Dollar"
                                    required={true}
                                    onChange={(e) => setOldDollar(e)}
                                />
                            </Form.Item>
                            <Form.Item label="Gold" required>
                                <InputNumber
                                    type="number"
                                    placeholder="Gold"
                                    required={true}
                                    onChange={(e) => setOldGold(e)}
                                />
                            </Form.Item>
                            <Form.Item label="Balance" required>
                                <InputNumber
                                    type="number"
                                    placeholder="Balance"
                                    required={true}
                                    onChange={(e) => setOldRoom(e)}
                                />
                            </Form.Item>
                        </Form>
                    </Modal>
                    <div
                        className="__container margin"
                        style={{ margin: "30px" }}
                    >
                        {data?.map((element) => {
                            const isHovered = hoveredElementId === element._id; // Check if this element is hovered
                            return (
                                <div
                                    className="hover-box-shadow"
                                    key={element._id}
                                >
                                    <Badge.Ribbon
                                        text={
                                            <div
                                                onMouseEnter={() =>
                                                    handleMouseEnter(
                                                        element._id,
                                                    )
                                                }
                                                onMouseLeave={handleMouseLeave}
                                            >
                                                {isHovered
                                                    ? (
                                                          element.clientsSum +
                                                          element.summ +
                                                          element.baseSum -
                                                          element.gold
                                                      ).toFixed(2)
                                                    : "*****"}
                                                gr
                                            </div>
                                        }
                                        color="orange"
                                    >
                                        <Badge.Ribbon
                                            style={{ marginTop: "30px" }}
                                            text={
                                                <div
                                                    onMouseEnter={() =>
                                                        handleMouseEnter(
                                                            element._id,
                                                        )
                                                    }
                                                    onMouseLeave={
                                                        handleMouseLeave
                                                    }
                                                >
                                                    {isHovered
                                                        ? (
                                                              ((element.clientsSum +
                                                                  element.summ +
                                                                  element.baseSum -
                                                                  element.gold) *
                                                                  config.gold_price) /
                                                              config.dollar_price
                                                          ).toFixed(2)
                                                        : "*****"}
                                                    $
                                                </div>
                                            }
                                            color="darkgreen"
                                        >
                                            <Card
                                                headStyle={{
                                                    textAlign: "center",
                                                    fontWeight: "bold",
                                                    fontSize: "40px",
                                                }}
                                                title={element.name}
                                            >
                                                <div
                                                    className="flex"
                                                    style={{
                                                        fontSize: "18px",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            margin: "0 auto",
                                                        }}
                                                    >
                                                        Dollar: $
                                                        <input
                                                            style={{
                                                                border: "none",
                                                                fontFamily:
                                                                    "inherit",
                                                                fontSize:
                                                                    "inherit",
                                                                textAlign:
                                                                    "center",
                                                                width: "80px",
                                                                margin: "0 auto",
                                                            }}
                                                            type="text"
                                                            onBlur={
                                                                UpdateGoldAndDollar
                                                            }
                                                            onChange={(e) => {
                                                                setDollar(
                                                                    e.target
                                                                        .value,
                                                                );
                                                                setOwner(
                                                                    element.name,
                                                                );
                                                            }}
                                                            defaultValue={
                                                                element.dollar
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            margin: "0 auto",
                                                        }}
                                                    >
                                                        Gold:
                                                        <input
                                                            style={{
                                                                border: "none",
                                                                fontFamily:
                                                                    "inherit",
                                                                fontSize:
                                                                    "inherit",
                                                                textAlign:
                                                                    "center",
                                                                width: "80px",
                                                            }}
                                                            type="text"
                                                            onBlur={
                                                                UpdateGoldAndDollar
                                                            }
                                                            onChange={(e) => {
                                                                setGold(
                                                                    e.target
                                                                        .value,
                                                                );
                                                                setOwner(
                                                                    element.name,
                                                                );
                                                            }}
                                                            defaultValue={
                                                                element.gold
                                                            }
                                                        />
                                                        gr
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        fontSize: "19px",
                                                        marginTop: "30px",
                                                    }}
                                                >
                                                    At Clients:{" "}
                                                    {element.clientsSum.toFixed(
                                                        2,
                                                    )}{" "}
                                                    gr
                                                    <br />
                                                    At Room:
                                                    <input
                                                        style={{
                                                            border: "none",
                                                            fontFamily:
                                                                "inherit",
                                                            fontSize: "inherit",
                                                            textAlign: "center",
                                                            width: "80px",
                                                        }}
                                                        type="text"
                                                        onChange={(e) => {
                                                            setRoom(
                                                                e.target.value,
                                                            );
                                                        }}
                                                        defaultValue={element.summ.toFixed(
                                                            2,
                                                        )}
                                                    />
                                                    gr
                                                    <br />
                                                    At Base:{" "}
                                                    {element.baseSum.toFixed(
                                                        2,
                                                    )}{" "}
                                                    gr
                                                    <br />
                                                    <b>
                                                        All:{" "}
                                                        {(
                                                            element.clientsSum +
                                                            element.summ +
                                                            element.baseSum
                                                        ).toFixed(2)}
                                                    </b>
                                                    <br />
                                                    <Link
                                                        onClick={() => {
                                                            updateRoom(
                                                                element._id,
                                                                room,
                                                            );
                                                        }}
                                                        style={{
                                                            float: "left",
                                                        }}
                                                    >
                                                        Update
                                                    </Link>
                                                    <Link
                                                        to={`/room/${element._id}`}
                                                        style={{
                                                            float: "right",
                                                        }}
                                                    >
                                                        Enter
                                                    </Link>
                                                </div>
                                            </Card>
                                        </Badge.Ribbon>
                                    </Badge.Ribbon>
                                </div>
                            );
                        })}
                    </div>

                    <Modal
                        title="Set Gold Price"
                        open={isFModal}
                        onOk={handleFOk}
                        onCancel={handleFCancel}
                    >
                        <Form.Item
                            label="Gold"
                            style={{
                                width: "400px",
                            }}
                        >
                            <Input
                                type="text"
                                width={"100%"}
                                placeholder="Gold price"
                                onChange={(e) => setGoldPrice(e.target.value)}
                            />
                        </Form.Item>
                    </Modal>
                </div>
            </>
        );
    }
};
export default App;
